<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>상황별 발생 건수(본사)</b>
		</div>
		<!-- Search -->
		<div ref="panelFlat" class="panel panel-flat">
			<div class="panel-body" style="width: 1300px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<div>
							<div class="form-group" style="padding-left: 10px">
								<label style="padding-right: 70px">현장</label>
								<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled" multiple="multiple">
									<option value="">전체</option>
									<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<div>
							<div class="form-group" style="padding-left: 10px">
								<label style="padding-right: 48px">위험대상</label>
								<select2 v-model="searchVM.dangerOptions" multiple="multiple">
									<option
										v-for="item in searchOptions.dangerOptions"
										:key="item.objectId + '-' + item.targetId"
										:value="item.objectId + '-' + item.targetId"
									>
										{{ item.objectNm }} - {{ item.targetNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<div>
							<div class="form-group" style="padding-left: 10px">
								<label style="padding-right: 48px">주의대상</label>
								<select2 v-model="searchVM.warnWorkOptions" multiple="multiple">
									<option
										v-for="item in searchOptions.warnWorkOptions"
										:key="item.objectId + '-' + item.targetId"
										:value="item.objectId + '-' + item.targetId"
									>
										{{ item.objectNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="form-group" style="display: flex; align-items: center">
								<!-- 발생구분 -->
								<label style="width: 120px">발생구분</label>
								<select2 v-model="searchVM.eventType">
									<option value="">전체</option>
									<option v-for="item in searchOptions.eventTypeOptions" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-5" period style="width: 750px">
							<div class="form-group" period>
								<label style="padding-right: 60px">요청일자</label>
								<input type="date" class="form-control" style="margin-right: 5px" v-model="searchVM.periodStrtDt" :format="'yyyy-MM-dd'" />
								~
								<input
									type="date"
									class="form-control"
									style="margin-left: 10px"
									v-model="searchVM.periodEndDt"
									:format="'yyyy-MM-dd'"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div id="detailsChart" class="row">
			<div class="col-lg-12">
				<!-- 1. 일별 API 호출(최근N일) -->
				<div class="panel panel-flat">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-stats-growth position-left"></i>
							<b>막대 차트</b>
						</h6>
					</div>
					<div class="panel-body">
						<div id="columnChart"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Select2 from '@/components/Select2';
import moment from 'moment';
import apiIndex from '../../api/index';
const objectDangerOccurStatistic = apiIndex.objectDangerOccurStatistic;

let axiosExtention;

export default {
	components: {
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		searchVM: {
			siteId: [],
			eventType: '',
			periodStrtDt: '',
			periodEndDt: '',
			dangerOptions: [],
			warnWorkOptions: [],
		},
		// 검색 select options
		searchOptions: {
			siteOptions: [],
			objectTargetOptions: [],
			dangerOptions: [],
			warnWorkOptions: [],
		},
		isSiteDisabled: false,
		//periodEndDt의 지정 가능한 최대 일자
		maxDay: '',
	}),
	async created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('objectDangerOccurStatisticBar2PageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;

		this.$set(this.searchVM.siteId, 0, this.pageParam.loginUserSiteId);

		this.searchVM.periodStrtDt = moment().add(-6, 'day').format('YYYY-MM-DD');
		this.searchVM.periodEndDt = moment().format('YYYY-MM-DD');

		this.searchOptions.siteOptions = this.pageParam.siteOptions;
		this.searchOptions.eventTypeOptions = [
			{
				value: 'danger',
				text: '위험',
			},
			{
				value: 'warnWork',
				text: '주의',
			},
		];
		await this.reloadObjectTargetOptions();

		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	computed: {
		//차트
		charData() {
			return {
				title: {
					text: '상황별 발생 건수(본사)',
				},
				seriesDefaults: {
					type: 'bar',
					overlay: { gradient: 'none' },
					labels: {
						visible: true,
						template: '#= kendo.format("{0} 건", data.value)#',
					},
				},
				series: [],
				// X축
				valueAxis: [
					{
						title: {
							text: '발생건수',
						},
						min: 0,
					},
				],
				// Y축
				categoryAxis: {
					categories: [],
				},
				tooltip: {
					visible: true,
					template: '#= series.name #: #= value # 건',
				},
			};
		},
	},
	watch: {
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(12, 'month').format('YYYY-MM-DD');
			},
		},
		'searchVM.siteId': {
			handler(newVal, oldVal) {
				if (oldVal[0] !== '' && newVal[0] === '') {
					// 전체를 선택한 경우 나머지 현장 다 지우기
					this.$nextTick(function () {
						this.searchVM.siteId.splice(1, this.searchVM.siteId.length - 1);
					});
				} else if (oldVal[0] === '' && this.searchVM.siteId.length > 1) {
					// 전체가 있는 상황에서 특정 현장을 선택한 경우, 전체를 지우기
					this.$nextTick(function () {
						this.searchVM.siteId.splice(0, 1);
					});
				}
			},
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('columnChart');
			this.$nextTick(function () {
				window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' });
			});
		},
		//유효한 요청일자 기간인지 확인
		isValidPeriod() {
			if (
				this.searchVM.periodStrtDt == null ||
				this.searchVM.periodStrtDt == '' ||
				this.searchVM.periodEndDt == null ||
				this.searchVM.periodEndDt == ''
			) {
				alert('날짜를 입력해주세요');
				return false;
			}
			if (new Date(this.searchVM.periodStrtDt) > new Date(this.searchVM.periodEndDt)) {
				alert('유효한 날짜를 입력해주세요');
				return false;
			}
			return true;
		},
		resetChartData() {
			this.chartJson = [];
			this.charData.series = [];
			this.charData.categoryAxis = {
				categories: [],
			};
		},
		async search() {
			await this.reloadObjectTargetOptions();

			if (this.isValidPeriod() == false) {
				return;
			}

			if (this.searchVM.siteId.length < 1) {
				return alert('현장을 선택해 주세요');
			}

			this.searchVM.objectAndTargets = this.searchVM.dangerOptions.concat(this.searchVM.warnWorkOptions);

			if (this.searchVM.objectAndTargets.length < 1) {
				return alert('대상물-조치사항 항목을 최소 1개 이상 선택해주세요.');
			}

			// 차트 데이터 초기화
			this.resetChartData();

			// select에 object넣는 방법을 몰라 아래와 같이 처리함

			// 선택된 siteId 값으로 searchOption에서 찾아서 새로 객체 생성
			let sites = [];
			// 현장이 전체로 선택되어 있으면
			if (this.searchVM.siteId.length == 1 && this.searchVM.siteId[0] == '') {
				// 모든 현장 데이터 넣기
				for (let i = 0; i < this.searchOptions.siteOptions.length; i++) {
					const element = this.searchOptions.siteOptions[i];
					sites.push({ siteId: element.siteId, siteNm: element.siteNm });
				}
			} else {
				for (let i = 0; i < this.searchVM.siteId.length; i++) {
					const siteId = this.searchVM.siteId[i];
					for (let j = 0; j < this.searchOptions.siteOptions.length; j++) {
						const element = this.searchOptions.siteOptions[j];
						if (siteId == element.siteId) {
							sites.push({ siteId: element.siteId, siteNm: element.siteNm });
							break;
						}
					}
				}
			}

			// 선택된 objectId + targetId 값으로 searchOption에서 찾아서 새로 객체 생성
			let objectAndTargets = [];
			for (let i = 0; i < this.searchVM.objectAndTargets.length; i++) {
				const objectTarget = this.searchVM.objectAndTargets[i];
				for (let j = 0; j < this.searchOptions.objectTargetOptions.length; j++) {
					const element = this.searchOptions.objectTargetOptions[j];
					if (objectTarget == element.objectId + '-' + element.targetId) {
						objectAndTargets.push({ objectId: element.objectId, objectNm: element.objectNm, targetId: element.targetId, targetNm: element.targetNm });
						break;
					}
				}
			}

			// 바 차트 데이터 불러오기
			this.loadBarChartData({
				siteId: sites,
				objectAndTargets,
				periodStrtDt: this.searchVM.periodStrtDt,
				periodEndDt: this.searchVM.periodEndDt,
				eventType: this.searchVM.eventType,
			});
		},
		// 차트 데이터 불러오기
		loadBarChartData(searchVM) {
			this.$axios
				.post(objectDangerOccurStatistic.inqDangerBarChart, searchVM)
				.then(
					function (response) {
						this.getDetailsFormPositionForScrolling();
						this.parsingChartData(response.data);
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		// 불러온 데이터 그래프 형식에 맞게 parsing
		parsingChartData(data) {
			// 대상물-조치사항 key
			let objectTargetKeys = Object.keys(data);

			// 전체 값에서 조회된 현장명들 가져오기
			let siteIds = Object.keys(data[objectTargetKeys[0]]);
			for (let i = 0; i < siteIds.length; i++) {
				// y축에 현장명 세팅
				this.$set(this.charData.categoryAxis.categories, i, siteIds[i]);
			}

			// 그래프 x축 값 세팅
			let series = [];

			for (let i = 0; i < objectTargetKeys.length; i++) {
				let objectTarget = {
					// 대상물-조치사항명
					name: objectTargetKeys[i],
					data: [],
				};

				let siteIdKeys = Object.keys(data[objectTargetKeys[i]]);
				for (let j = 0; j < siteIdKeys.length; j++) {
					// 대상물-조치사항 안의 각 현장의 count
					objectTarget.data.push(data[objectTargetKeys[i]][siteIdKeys[j]]);
				}
				series.push(objectTarget);
			}
			// series에 parsing 데이터 push
			for (let i = 0; i < objectTargetKeys.length; i++) {
				this.$set(this.charData.series, i, series[i]);
			}

			this.drawChart();
		},
		drawChart() {
			console.log(this.charData);
			$('#columnChart').kendoChart({
				series: this.charData.series,
				seriesDefaults: this.charData.seriesDefaults, //x축 y축 자리 교체
				categoryAxis: this.charData.categoryAxis, // y축 어떻게 표현할지
				valueAxis: this.charData.valueAxis, // x축 어떻게 표현할지
				chartArea: { width: this.$refs.panelFlat.clientWidth - 50, height: 6 * 130 }, // 차트 크기 조정
				legend: {
					position: 'bottom',
				},
				tooltip: this.charData.tooltip,
				seriesColors: ['#e63946', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#76c893'],
			});
		},
		async reloadObjectTargetOptions() {
			this.searchOptions.objectTargetOptions = await this.$axios
				.post(objectDangerOccurStatistic.inqChartObjectTargetList, { eventType: '' })
				.then(res => res.data);

			this.searchOptions.dangerOptions = this.searchOptions.objectTargetOptions.filter(option => option.isWarnWork !== '1');
			this.searchOptions.warnWorkOptions = this.searchOptions.objectTargetOptions.filter(option => option.isWarnWork === '1');
		},
	},
};
</script>

<style>
span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}
.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}

.row .form-group > span {
	width: 83% !important;
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

#detailsForm > .panel > .panel-heading > div {
	display: flex;
	margin-bottom: 15px;
}
#detailsForm > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
}
#detailsForm > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
#detailsForm > .panel > .panel-heading span {
	margin-right: 10px;
}

#detailsForm > .panel > .panel-body > div {
	width: calc(50% - 10px);
	display: inline-flex;
	flex-direction: column;
}
#detailsForm > .panel > .panel-body > div:last-child {
	float: right;
}

#detailsForm .panel-heading {
	font-weight: bold;
}
#detailsForm .panel-heading input {
	max-width: 160px;
}

#detailsForm .law-panel .panel-body textarea {
	height: 200px;
	resize: none;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
#detailsForm .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
#detailsForm tr.active td {
	background: #ddd;
}

/* #detailsForm .panel-body .panel-flat { max-height:100px; } */
#detailsForm .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

#detailsForm .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}
.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 30px;
	padding-right: 20px;
}
.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

[period] [type='date'] {
	width: 150px !important;
}

.col-lg-12 {
	padding-right: 0 !important;
}
</style>
